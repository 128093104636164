import React, { useCallback, useRef, useState, useEffect } from 'react';
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Dropdown } from "react-bootstrap";
import Button2 from 'devextreme-react/button';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../redux/slice/userSlice'
import { selectFiltersGridDP } from '../redux/slice/gridDetallePrecioSlice'
import { selectFilters } from '../redux/slice/configSlice'
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import DataGrid, { GroupPanel, Column, HeaderFilter, Search, ColumnChooser, ColumnFixing, Scrolling, Pager, Paging, Export, Selection, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo, Toolbar, Item, Grouping, FilterRow, FilterPanel } from 'devextreme-react/data-grid';
import List, { ListTypes } from 'devextreme-react/list';
import TreeView, { TreeViewTypes } from 'devextreme-react/tree-view';
import DropDownBox, { DropDownBoxTypes } from 'devextreme-react/drop-down-box';
import 'jspdf-autotable';

import Chart from 'react-apexcharts';

import * as ExtrasService from '../services/extras'

import Select from "react-select";
import { SET_DATE, SET_END_DATE, SET_START_DATE, selectDate } from '../redux/slice/dateSlice';
import { REMOVE_DATA_FILTER_DP } from '../redux/slice/gridDetallePrecioSlice';

export default function ComparativaSKU() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector(selectUser)
  const filters = useSelector(selectFiltersGridDP)
  const filtersSelect = useSelector(selectFilters)
  const globalDate = useSelector(selectDate);
  const [datos, SetDatos] = useState([]);
  const [dataCatCliente, SetDataCatCliente] = useState([])
  const [dataCatCompe, SetDataCatCompe] = useState([])
  const [dataCatCompe2, SetDataCatCompe2] = useState([])
  const [listSKU, SetListSKU] = useState([])

  const [filtersGrid, SetFiltersGrid] = useState([])
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [treeViewReady, SetTreeView] = useState(false);
  const treeViewRef = useRef(null);
  const [treeBoxValue, setTreeBoxValue] = useState();
  const [valueFull, setValueFull] = useState();
  const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);

  const treeViewRef2 = useRef(null);
  const [treeBoxValue2, setTreeBoxValue2] = useState();
  const [value2Full, setValue2Full] = useState();
  const [isTreeBoxOpened2, setIsTreeBoxOpened2] = useState(false);

  const [chartReady, SetChartReady] = useState(false);
  const [seriesData, SetSeriesData] = useState([])

  const [chartOptions, SetChartOptions] = useState({
    series: seriesData,
    options: {
      chart: {
        type: 'line',
      },
      xaxis: {
        categories: [],
      },
    },
  })

  const listCurrency = [
    { value: 'Dolares', label: 'Dolares' },
    { value: 'Bolivares', label: 'Bolivares' }
  ]

  const [currencySelected, setCurrencySelected] = useState(listCurrency[0]);
  const [skuSelected, setSKUSelected] = useState();
  const ownerLabel = { 'aria-label': 'Owner' };

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  useEffect(() => {
    if (datos != undefined && skuSelected != undefined && currencySelected != undefined) {
      AnalizarSKU()
    }
  }, [datos, skuSelected, currencySelected]);

  const GetDataSKU = () => {
    let value = filtersSelect.client == null ? userData.cliente[0] : filtersSelect.client
    let region = filtersSelect.region == null ? 'TODAS LAS REGIONES' : filtersSelect.region

    let info = {
      client_name: value,
      region_name: region,
      fecha_ini: moment(startDate).format('YYYY/MM/DD'),
      fecha_fin: moment(endDate).format('YYYY/MM/DD')
    }
    console.log("🚀 ~ GetDataSKU ~ info:", info)

    ExtrasService.CompareSKU_Stores(info).then(resp => {
      let data = resp.data
      console.log('-----------------------')
      console.log(data)
      SetDatos(data)
      SetListSKU(data.data_sku)
      setSKUSelected(data.data_sku[0].items[0])
      console.log('-----------------------')
    })
  }

  function ItemTemplate(data) {
    return <div onClick={() => { setSKUSelected(data); }}>{data}</div>;
  }

  const SelectSKU = (e) => {
    setSKUSelected(e.itemData.text)
  }

  const SelectCurrency = (currency) => {
    // console.log(currency)
    setCurrencySelected(currency)
  }

  const AnalizarSKU = () => {
    SetChartReady(false)
    console.log("🚀 ~ AnalizarSKU ~ currencySelected:", currencySelected)
    // console.log("🚀 ~ AnalizarSKU ~ skuSelected:", skuSelected)
    console.log("🚀 ~ AnalizarSKU ~ datos:", datos)

    let data1 = []
    console.log("🚀 ~ AnalizarSKU ~ datos.data_ds:", datos.data_ds)
    console.log("🚀 ~ AnalizarSKU ~ datos.data_bs:", datos.data_bs)
    if (currencySelected.value == 'Bolivares') {
      data1 = datos.data_bs.filter((x) => x.PRODUCT == skuSelected)
    } else {
      console.log('estoy aqui??')
      data1 = datos.data_ds.filter((x) => x.PRODUCT == skuSelected)
    }
    console.log("🚀 ~ AnalizarSKU ~ data1:", data1)

    let fechas = data1.map(x => {return x.DATE_FORM})
    fechas = [...new Set(fechas)]
    let data_client = []
    let data_client2 = []
    let data_competence = []
    let data_competence2 = []
    let series = seriesData

    let series_data = []
    // fechas.push(element.DATE_FORM)
    datos.data_store.forEach(store => {
      let data_serie = {
        name: store,
        data: []
      }
      fechas.forEach(fecha => {
        console.log("🚀 ~ AnalizarSKU ~ store:", store)
        let info = data1.filter(x => x.STORE_FORM == store && x.DATE_FORM == fecha)
        info = info.length > 0 ? Number(info[0].ANSWER) : 0
        console.log("🚀 ~ AnalizarSKU ~ info:", info)
        data_serie.data.push(info)
        // data_serie = {
        //   name: store,
        //   data: info.map(y => {return Number(y.ANSWER)})
        // }
        console.log("🚀 ~ AnalizarSKU ~ data_serie:", data_serie)
      })
      console.log("🚀 ~ AnalizarSKU ~ data_serie:", data_serie)
      series_data.push(data_serie)
    })
    series = series_data
    console.log("🚀 ~ AnalizarSKU ~ series:", series)

    console.log("🚀 ~ AnalizarSKU ~ fechas:", fechas)
    let options = chartOptions
    options.options.xaxis.categories = fechas
    options.series = series
    console.log("🚀 ~ AnalizarSKU ~ options:", options)

    SetSeriesData(series)
    console.log("🚀 ~ AnalizarSKU ~ series:", series)
    SetChartOptions(options)
    setTimeout(() => {
      SetChartReady(true)
    }, 500);
  }

  const GroupTemplate = (item) => <div>Categoria: {item.key}</div>;

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="grid_store_no_alcanzados main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Comparación de SKU entre establecimientos</h4>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">

          </div>
        </div>

        <div className="height-grid-75">
          <div className='row '>
            <div className='col-3'>
              <ReactDatePicker selected={startDate} onChange={(date) => { setStartDate(date) }} className="form-control" />
            </div>
            <div className='col-3'>
              <ReactDatePicker selected={endDate} onChange={(date) => { setEndDate(date) }} className="form-control" />
            </div>
            <div className='col-1'>
              <Button variant="primary" className="d-flex align-items-center gap-2" onClick={(e) => GetDataSKU()}>
                <i className="ri-search-2-line fs-18 lh-1"></i>Buscar<span className="d-none d-sm-inline"></span>
              </Button>
            </div>
          </div>

        </div>

        <div className='row'>
          <div className='col-md-4 mt-4'>
            <List
              dataSource={listSKU}
              height={500}
              grouped={true}
              groupRender={GroupTemplate}
              collapsibleGroups={true}
              searchEnabled={true}
              onItemClick={SelectSKU}
              searchMode='contains' />
          </div>

          <div className='col-md-8 mt-4'>
            <div className='row'>
              <div className='col-md-6' style={{margin: 'auto'}}>
                <h6>
                  SKU: {skuSelected}
                </h6>
              </div>
              <div className='col-md-6 row'>
                <div className='col-md-4' style={{margin: 'auto'}}>
                  <span className='mr-2'>
                    Tipo de Moneda
                  </span>
                </div>
                <div className='col-md-8'>
                  <Select options={listCurrency} value={currencySelected} onChange={(e) => { setCurrencySelected(e); }} isSearchable={true} />
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              {chartReady == true &&
                <Chart
                  options={chartOptions.options}
                  series={chartOptions.series}
                  type="line"
                  height={450}
                />
              }
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  )
}