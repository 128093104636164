import { database, authentication } from "./../firebase/firebase";
import { toast } from 'react-toastify';
import { Button, Card, Form, Col, Row, Toast } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SET_USER, REMOVE_USER } from '../redux/slice/userSlice';
import { SET_FILTERS_REPORTS_AUTO, REMOVE_FILTERS_REPORTS_AUTO } from '../redux/slice/reportsAutoSlice';
import { REMOVE_ACTIVE_USER } from "../redux/slice/authSlice";
import { React, useEffect, useState } from "react";
import { RingLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import Loader from '../components/loader/Loader'
import './style.scss'

import * as LoginService from '../services/login'

export default function Signin() {

  const imageBack = require('../assets/img/imageBackLogin.jpg')

  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false)
  // const [user, SetUser] = useState({email: 'cesar.deproinf@gmail.com',password: '123456789'})
  const [user, SetUser] = useState({email: '',password: ''})

  useEffect(() => {

    if(action != undefined){
      dispatch(SET_FILTERS_REPORTS_AUTO({ id: id }))
    } else {
      dispatch(REMOVE_FILTERS_REPORTS_AUTO())
    }
    console.log(action)
    console.log(id)
    localStorage.clear()
    dispatch(REMOVE_USER())
    dispatch(REMOVE_ACTIVE_USER())
  }, [])

  const OnSubmitForm = () => {

    setisLoading(true)
    authentication.signInWithEmailAndPassword(user.email, user.password).then(res => {
      const uidUser = res.user.uid
      database.collection('Usuarios').doc(uidUser).get().then((doc) => {
        const dataUser = doc.data()
        console.log("🚀 ~ database.collection ~ dataUser:", dataUser)
        dispatch(SET_USER({
          user: dataUser
        }))
        let infoUser = {
          name: `${dataUser.nombre} ${dataUser.apellido}`,
          position: `${dataUser.perfil}`
        }
        localStorage.setItem('infoUser', JSON.stringify(infoUser))

        // if(action == 1){
        //   navigate('/dashboard/mainDashboard')
        // } else {
          navigate('/Grids/Tasa')
        // }
        
      })
      setisLoading(false)
    }).catch(err => {
      console.log(err)
      setisLoading(false)
    })
  }

  return (
    <div className="container ComponentLogin"> 
      {isLoading && <Loader />}
      <div className="page-sign">
        <Card className="card-sign card_login">
          <Card.Header>
            <Link to="/" className="header-logo mb-4">Mobility Analytics</Link>
            <Card.Title>Iniciar Sesión</Card.Title>
            <Card.Text>¡Bienvenido de nuevo! Inicie sesión por favor.</Card.Text>
          </Card.Header>
          <Card.Body>
            <Form method="post" action="/dashboard/finance">
              <div className="mb-4">
                <Form.Label >Email</Form.Label>
                <Form.Control name="username" id="username" type="text" placeholder="Ingresar dirección de correo" onChange={e => SetUser({ ...user, email: e.target.value })} />
              </div>
              <div className="mb-4">
                <Form.Label className="d-flex justify-content-between">Contraseña </Form.Label>
                <Form.Control id="password" type="password" placeholder="Ingresar su contraseña" onChange={e => SetUser({ ...user, password: e.target.value })} />
              </div>
                <Form.Label className="d-flex justify-content-end"> <Link to="">¿Olvistate tu contraseña?</Link></Form.Label>
              <Button id="buttonLogin" onClick={() => OnSubmitForm()} variant="primary" className="btn-sign">Iniciar Sesión</Button>

            </Form>
          </Card.Body>
          <Card.Footer>
          </Card.Footer>
        </Card>
      </div>
    </div>
  )
}